<template>
  <div class="fixed inset-0 z-50 flex items-center justify-center px-4">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75" @click="$emit('close')"></div>

    <div class="bg-white max-w-lg w-full relative z-10 p-6 rounded-md">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 absolute top-0 right-0 text-gray-400 hover:text-gray-900 mt-4 mr-4 cursor-pointer"
        viewBox="0 0 20 20"
        fill="currentColor"
        data-test="idc.close"
        @click="$emit('close')"
      >
        <path
          fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
      <h1 class=" text-gray-900 text-lg  m-0 leading-none">
        <span class="font-medium" data-test="idc.mainTitle">Change Inception Date</span>
      </h1>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form class="mt-4" @submit.prevent="handleSubmit(submit)">
          <div v-if="!newPolicyNumber && !pendingCancel && !newPolicyLoading">
            <ValidationProvider v-slot="{ errors }" name="New Inception Date" rules="">
              <div class="mb-2">
                <label
                  for="newAgentOfRecord"
                  class="text-sm text-gray-700 leading-none"
                  data-test="idc.newDateTitle"
                  >New Inception Date</label
                >
                <el-date-picker
                  v-model="newInceptionDate"
                  style="width: 100%;"
                  class="mb-1 focus:ring-deepsea-medium focus:border-deepsea-medium block w-full [&_input]:!rounded-md sm:text-sm [&_input]:border [&_input]:border-gray-300 [&_input]:!pl-4.5 [&_input]:!py-2 [&_input]:h-[38px] disabled:!bg-gray-100 disabled:!text-gray-500"
                  :clearable="false"
                  format="MM/dd/yyyy"
                  :picker-options="pickerOptions"
                  :disabled="loading || newPolicyNumber ? true : false || pendingCancel"
                  data-test="idc.datePicker"
                  :class="{
                    'border-red-500': errors[0],
                    'border-gray-300': !errors[0]
                  }"
                />
                <p class="text-gray-500 text-xs mt-0" data-test="idc.dateNote">
                  <span class="font-bold">Note:</span>
                  Changing the inception date will cancel this policy and rewrite a new policy.
                </p>
              </div>
            </ValidationProvider>
            <ValidationProvider
              v-if="$internal"
              v-slot="{ errors }"
              name="New Agent of Record"
              rules=""
              class="mt-4"
              tag="div"
            >
              <div class="mb-2">
                <label
                  for="newAgencyNumber"
                  class="text-sm text-gray-700 leading-none"
                  data-test="idc.agentNoTitle"
                  >New Agent Number <span class="text-gray-500 text-xs">(optional)</span></label
                >
                <p
                  class="text-gray-500 text-xs mb-1.5 leading-none"
                  data-test="idc.agentNoSubtitle"
                >
                  Enter new agency number to change the agent of record
                </p>

                <div class="relative">
                  <input
                    v-model="newAgencyNumber"
                    name="newAgencyNumber"
                    type="text"
                    placeholder="e.x. FL0008"
                    class="focus:ring-deepsea-medium focus:border-deepsea-medium block w-full !rounded-md sm:text-sm border border-gray-300 pl-2.5 py-2 disabled:bg-[#F5F7FA] disabled:text-[#C0C4CC] disabled:border-[#E4E7ED] disabled:cursor-not-allowed"
                    :class="{
                      'border-red-500': errors[0],
                      'border-gray-300': !errors[0]
                    }"
                    data-test="idc.agentNoInput"
                    :disabled="loading || newPolicyNumber || pendingCancel"
                    @blur="getAgencyDetails(newAgencyNumber)"
                  />
                </div>
                <FadeTranslate>
                  <p
                    v-if="errors[0]"
                    class="text-red-500 text-sm m-0"
                    data-test="idc.agentNoErrors"
                  >
                    {{ errors[0] }}
                  </p>
                </FadeTranslate>
                <div v-if="!loadingAgencyDetails" data-test="idc.selectedAorSection">
                  <span class="text-xs text-gray-500" data-test="idc.selectedAorTitle"
                    >Selected Agent of Record:
                  </span>
                  <span class="text-xs text-gray-500 font-bold" data-test="idc.selectedAor"
                    >{{ agencyInfo.company }} — {{ agencyInfo.agencyNo }}</span
                  >
                </div>
              </div>
            </ValidationProvider>

            <ValidationProvider
              v-if="$internal"
              v-slot="{ errors }"
              name="Producer Name"
              rules=""
              class="mt-4"
              tag="div"
            >
              <div class="mb-4">
                <label
                  for="newProducerName"
                  class="text-sm text-gray-700 leading-none"
                  data-test="idc.producerNameTitle"
                  >Producer Name <span class="text-gray-500 text-xs">(optional)</span></label
                >
                <div class="relative">
                  <input
                    v-model="newProducerName"
                    name="newProducerName"
                    type="text"
                    placeholder=""
                    :disabled="loading || newPolicyNumber || pendingCancel"
                    class="focus:ring-deepsea-medium focus:border-deepsea-medium block w-full !rounded-md sm:text-sm border border-gray-300 pl-2.5 py-2 disabled:bg-[#F5F7FA] disabled:text-[#C0C4CC] disabled:border-[#E4E7ED] disabled:cursor-not-allowed"
                    :class="{
                      'border-red-500': errors[0],
                      'border-gray-300': !errors[0]
                    }"
                    data-test="idc.producerNameInput"
                  />
                </div>
                <FadeTranslate>
                  <p
                    v-if="errors[0]"
                    class="text-red-500 text-sm m-0"
                    data-test="idc.producerNameError"
                  >
                    {{ errors[0] }}
                  </p>
                </FadeTranslate>
              </div>
            </ValidationProvider>
          </div>

          <div
            v-else-if="newPolicyNumber || pendingCancel"
            class="text-sm mt-4 p-3 bg-blue-50 text-blue-900 border-blue-300 border rounded mb-4"
            data-test="idc.successMessage"
          >
            Policy
            <span class="font-bold" data-test="idc.cancelledPolicyNo">{{
              $route.params.policyNo
            }}</span>
            has been cancelled. The new policy number is
            <a
              v-if="!newPolicyLoading"
              class="underline cursor-pointer"
              data-test="idc.newPolicyNo"
              @click="
                (e) => {
                  e.preventDefault();
                  window.open(
                    `#${$route.fullPath.replace($route.params.policyNo, newPolicyNumber)}`,
                    '_blank'
                  );
                  $emit('close');
                }
              "
            >
              {{ newPolicyNumber }}</a
            >
            <ContentLoader
              v-else
              class="inline-block w-[83px]"
              view-box="0 0 83 15"
              width="83"
              height="15"
              :speed="1"
              primary-color="#bae6fd"
              secondary-color="#7dd3fc"
            >
              <rect x="0" y="0" rx="3" ry="3" width="83px" height="15" />
            </ContentLoader>

            .
            <span data-test="idc.newAgentNo"
              >The new agent of record is
              <span v-if="newAgencyNumber !== '' && !newPolicyLoading">
                {{ newAgencyNumber }}
              </span>
              <ContentLoader
                v-else
                class="inline-block w-[83px]"
                view-box="0 0 83 15"
                width="83"
                height="15"
                :speed="1"
                primary-color="#bae6fd"
                secondary-color="#7dd3fc"
              >
                <rect x="0" y="0" rx="3" ry="3" width="83px" height="15" />
              </ContentLoader>
              .</span
            >
            Please click the "View New Policy" button below to navigate to the new policy.
          </div>

          <ContentLoader v-else :speed="1" class="mb-2">
            <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
          </ContentLoader>

          <p
            v-if="hasImmediateClosing"
            class="text-sm mt-4 p-3 bg-blue-50 text-blue-900 border-blue-300 border rounded mb-2"
            data-test="idc.waitingPeriodMessage"
          >
            The standard 10-day waiting period can only be waived for properties involved in a real
            estate closing and/or when providing roll-over coverage from another flood insurance
            policy. Documentation must be provided in the form of a closing disclosure or settlement
            statement showing the exact date of closing matching the effective date, or a copy of
            the declaration page of other coverage showing an expiration date matching the effective
            date for roll-overs. Documents must be uploaded to the policy management system.
          </p>

          <div
            v-if="!newPolicyLoading"
            slot="footer"
            class="flex space-x-2"
            :class="{
              'justify-end': newPolicyNumber || pendingCancel,
              'justify-between': !newPolicyNumber && !pendingCancel
            }"
          >
            <div
              v-if="!newPolicyNumber && !pendingCancel && !newPolicyLoading"
              class="flex mt-1 items-center"
            >
              <p class="text-gray-500 text-sm" data-test="idc.immediateClosingTitle">
                Immediate Closing
              </p>
              <el-switch
                v-model="hasImmediateClosing"
                :disabled="
                  inceptionDateGreaterThanToday || loading || pendingCancel || newPolicyNumber
                    ? true
                    : false
                "
                class="mt-1 ml-3"
                active-color="#004C9D"
                data-test="idc.immediateClosingToggle"
              />
            </div>
            <div class="space-x-2 flex items-center">
              <button
                v-if="!newPolicyNumber && !pendingCancel"
                type="button"
                data-test="idc.cancelButton"
                class="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none "
                @click.prevent="$emit('close')"
              >
                Cancel
              </button>

              <button
                v-if="!newPolicyNumber && !pendingCancel"
                type="submit"
                class="inline-flex items-center rounded-md border border-transparent bg-deepsea-full px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-deepsea-full/75 focus:outline-none focus:ring-2 focus:ring-deepsea-medium focus:ring-offset-2 min-w-[69px] disabled:opacity-75 disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-not-allowed"
                data-test="idc.submitButton"
                :class="{ 'cursor-not-allowed': loading }"
                :disabled="loading"
                @click.prevent="handleSubmit(submit)"
              >
                <span v-if="!loading">
                  Update
                </span>
                <svg
                  v-else
                  class="animate-spin h-5 w-5 text-white mx-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </button>
              <button
                v-else
                class="inline-flex items-center rounded-md border border-transparent bg-deepsea-full px-4 py-2 text-sm font-medium !text-white shadow-sm hover:bg-deepsea-full/75 focus:outline-none focus:ring-2  min-w-[69px] hover:text-white hover:!no-underline"
                data-test="idc.viewNewPolicyButton"
                target="_blank"
                @click="
                  () => {
                    window.open(
                      `#${$route.fullPath.replace($route.params.policyNo, newPolicyNumber)}`,
                      '_blank'
                    );
                    $emit('close');
                  }
                "
              >
                <span>
                  View New Policy
                </span>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import moment from "moment";

import { mapActions } from "vuex";
import { ValidationProvider, ValidationObserver, setInteractionMode } from "vee-validate";
import FadeTranslate from "@/components/transitions/FadeTranslate.vue";
import { Constants } from "@/config";
import { authAxios } from "@/store/modules/auth";
import { ContentLoader } from "vue-content-loader";

setInteractionMode("eager");

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FadeTranslate,
    ContentLoader
  },

  props: {
    newPolicyNumber: {
      default: false
    }
  },
  data() {
    return {
      loading: true,
      newInceptionDate: null,
      cancellationDates: null,
      newProducerName: "",
      newAgencyNumber: "",
      newAgencyName: "",
      hasImmediateClosing: false,
      agencyInfo: "",
      loadingAgencyDetails: true,
      policies: [],
      skip: 0,
      newPolicyLoading: false,
      window: null
    };
  },
  computed: {
    inceptionDateGreaterThanToday() {
      const newDate = new Date(
        new Date(this.$store.getters["policy/allData"].inceptionDate).toLocaleString().slice(0, 10)
      );
      const today = new Date(new Date().toLocaleDateString());
      return newDate <= today;
    },
    pickerOptions() {
      return {
        disabledDate: (date) => {
          const max = new Date(this.cancellationDates.maxEffectiveDate);
          let min = new Date();
          if (this.hasImmediateClosing) {
            min = new Date(this.cancellationDates.minEffectiveDate_Immediate);
          } else {
            min = new Date(this.cancellationDates.minEffectiveDate_NotImmediate);
          }
          return !(date >= min && date <= max);
        }
      };
    },
    policyData() {
      return this.$store.getters["policy/allData"];
    },
    pendingCancel() {
      return this.policyData.isPendingCancel;
    },
    cancelled() {
      return this.policyData.isPolicyCancelled;
    },
    canChangeInceptionDate() {
      return this.$store?.state?.policy?.confirmationData?.uiConfiguration?.canChangeEffectiveDate;
    },
    sortedPolicies() {
      return this.$store.state.historicalPolicies
        .filter((policy) => !policy.isCancelled)
        .sort((a, b) => {
          const momentA = moment(a.originalPostDate);
          const momentB = moment(b.originalPostDate);
          return momentA.isBefore(momentB) ? 1 : -1;
        });
    }
  },
  watch: {
    async sortedPolicies(newValue) {
      if (this.pendingCancel === false && this.cancelled === false) {
        this.newPolicyLoading = false;
        return;
      }

      if (newValue.length) {
        const newPolicyNumber = this.sortedPolicies[0].policyNo;
        if (newPolicyNumber !== this.$route.params.policyNo) {
          this.newPolicyNumber = newPolicyNumber;

          // policy details
          const {
            data: { payload }
          } = await authAxios.get(
            `${Constants.BASE_PSDN_URL}/api/v1/policies/${newPolicyNumber}/getConfirmation`
          );

          // agency details
          const { data } = await authAxios.get(
            `${Constants.BASE_PSDN_URL}/api/v1/agencies/AgencyDetailsForPolicy/${payload.agencyNo}`
          );

          this.agencyInfo.agencyNo = payload.agencyNo;
          this.agencyInfo.company = data.payload.agencyInfo.company;
          this.newAgencyNumber = payload.agencyNo;
          this.newInceptionDate = payload.inceptionDate;
          this.newProducerName = data.payload.agencyInfo.company;

          this.newPolicyLoading = false;
        }
      }
    }
  },
  async mounted() {
    this.window = window;
    this.newPolicyLoading = true;
    this.newInceptionDate = this.policyData.inceptionDate;
    this.newAgencyNumber = this.policyData.agencyNo;
    this.newProducerName = this.policyData.producerName;
    await this.getCancellationDates();
    await this.getAgencyDetails(this.newAgencyNumber);

    if (this.sortedPolicies.length) {
      if (this.pendingCancel === false && this.cancelled === false) {
        this.newPolicyLoading = false;
        return;
      }

      const newPolicyNumber = this.sortedPolicies[0].policyNo;
      if (newPolicyNumber !== this.$route.params.policyNo) {
        this.newPolicyNumber = newPolicyNumber;

        // policy details
        const {
          data: { payload }
        } = await authAxios.get(
          `${Constants.BASE_PSDN_URL}/api/v1/policies/${newPolicyNumber}/getConfirmation`
        );

        // agency details
        const { data } = await authAxios.get(
          `${Constants.BASE_PSDN_URL}/api/v1/agencies/AgencyDetailsForPolicy/${payload.agencyNo}`
        );

        this.agencyInfo.agencyNo = payload.agencyNo;
        this.agencyInfo.company = data.payload.agencyInfo.company;
        this.newAgencyNumber = payload.agencyNo;
        this.newInceptionDate = payload.inceptionDate;
        this.newProducerName = data.payload.agencyInfo.company;

        this.newPolicyLoading = false;
      }
    }
  },
  methods: {
    async getAgencyDetails(agentNo) {
      try {
        this.loadingAgencyDetails = true;
        const {
          data: {
            payload: { agencyInfo }
          }
        } = await authAxios.get(
          Constants.AGENCY_DETAILS_FOR_POLICY.replace("{{agentNo}}", agentNo)
        );

        this.agencyInfo = agencyInfo;
      } catch (e) {
        throw new Error(e);
      } finally {
        this.loadingAgencyDetails = false;
      }
    },
    async getCancellationDates() {
      try {
        const res = await authAxios.get(
          Constants.GET_CANCEL_REWRITE_DATES.replace("{{policyNo}}", this.$route.params.policyNo)
        );
        this.cancellationDates = res.data.payload;
      } catch (e) {
        throw new Error(e);
      } finally {
        this.loading = false;
      }
    },
    ...mapActions("policy", {
      sendNewLinkAxios: "sendNewLinkAxios"
    }),
    async submit() {
      try {
        this.loading = true;
        const url = Constants.CANCEL_REWRITE_URL.replace(
          "{{policyNo}}",
          this.$route.params.policyNo
        );
        const { data } = await authAxios.post(url, {
          inceptionDate: this.newInceptionDate,
          newAgencyNo: this.newAgencyNumber,
          hasImmediateClosing: this.hasImmediateClosing === null ? false : this.hasImmediateClosing,
          producerName: this.newProducerName
        });
        this.$emit("newPolicyNumber", data.payload.policy.policyNo);

        Swal.fire({
          title: "Success!",
          html: `The policy's inception date has been updated. The new policy can be viewed by clicking the button below.`,
          icon: "success",
          confirmButtonColor: "#004C9D",
          confirmButtonText: "View Policy",
          showCloseButton: true
        }).then((result) => {
          if (result.isConfirmed) {
            window.open(`/#/policy/embed/${data.payload.policy.policyNo}`, "_blank");
          }
        });

        this.$root.$emit("refreshPolicyData");
        this.$emit("close");
      } catch (e) {
        const message = e.response?.data?.message ?? e.message;
        Swal.fire({
          title: "Error!",
          text: message,
          icon: "error",
          confirmButtonColor: "#004C9D",
          showConfirmButton: true
        });
        this.$emit("close");

        throw new Error(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
