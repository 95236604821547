var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"my-0.5 px-2.5 py-1 rounded-full relative z-10 inline-block !text-sm normal-case",class:{
    'text-amber-800 bg-amber-100 ': _vm.rewardLevel.name === 'Bronze',
    'text-slate-800 bg-slate-100 ': _vm.rewardLevel.name === 'Silver',
    'text-yellow-800 bg-yellow-100 ': _vm.rewardLevel.name === 'Gold',
    'text-sky-800 bg-sky-100 ': _vm.rewardLevel.name === 'Platinum',
    'text-teal-800 bg-teal-100 ': _vm.rewardLevel.name === 'Diamond'
  }},[_vm._v(_vm._s(_vm.rewardLevel.name)+" Agent")])
}
var staticRenderFns = []

export { render, staticRenderFns }