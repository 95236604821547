import { parseBool } from "@/utils/parseBool";
import "./Styles";
import "./VueOptions";

const WESTPOINT = {
  DEEP_LINK:
    // eslint-disable-next-line no-template-curly-in-string
    "/NOIT_Rating/HistoryPage.aspx?PolicyID=${policy}&InceptionDate=${inception}"
};

const PROPERTY_TYPES = {
  PRIMARY: "primaryHome",
  SECONDARY: "secondaryHome",
  RENTAL_OWNER: "rentalOwner",
  RENTAL_TENANT: "rentalTenant"
};

const FOUNDATION_TYPES = {
  SLAB: "slab",
  CRAWLSPACE: "crawlspace",
  STILTS_VENTS: "stiltsWithVents",
  STILTS_SOLID: "stiltsWithSolidEnclosure",
  GARAGE_UNFINISHED: "unfinishedGarage",
  GARAGE_FINISHED: "garageWithNonElevatedUtilities",
  BASEMENT_UNFINISHED: "unfinishedBasement",
  BASEMENT_FINISHED: "finishedBasement"
};

const BILLING = {
  INSURED: "insured",
  COAPPLICANT: "coapplicant",
  LIENHOLDER: "lienholder"
};

const PAYTMENT = {
  CREDIT: "credit",
  ACH: "ach",
  CHECK: "check"
};

const DELIVERY = {
  ELECTRONIC: "electronic",
  PRINT: "print"
};

const OCCUPANCY = {
  SINGLE: "singleFamily",
  MULTI: "multiFamily",
  CONDO: "condo",
  MFG: "manufactured"
};

const STORIES = {
  ONE: "1",
  TWO: "2",
  THREE: "3",
  FOUR: "4"
};

const CONSTRUCTION_TYPE = {
  MASONRY: "masonry",
  WOOD: "wood",
  STEEL: "steel",
  CONCRETE: "concrete",
  OTHER: "other"
};

const NUMLOSS = {
  NONE: "none",
  ONE_UNDER_25K: "oneUnder25k",
  ONE_OVER_24K: "oneOver25k",
  TWO_OR_MORE: "twoOrMore"
};

const BASE_TRITON_URL = process.env.VUE_APP_BASE_TRITON_URL;
const NEW_AGENT_URL = process.env.VUE_APP_NEW_AGENT_URL;
const BASE_PSDN_URL = process.env.VUE_APP_BASE_PSDN_URL;
const PSDN_UI_BASE_URL = process.env.VUE_APP_PSDN_UI_BASE_URL;
const AUTHKEY = process.env.VUE_APP_AUTHKEY;
const skipIndicators = parseBool(process.env.VUE_APP_SKIP_INDICATORS);
const DEBUG = parseBool(process.env.VUE_APP_DEBUG);
const BASE_TRITON_UI_URL = process.env.VUE_APP_BASE_TRITON_UI_URL;
const BASE_DTC_WIDGET_URL = process.env.VUE_APP_BASE_DTC_WIDGET_URL;
const ENV_DESCRIPTION = process.env.VUE_APP_ENV_DESCRIPTION;
const AGENCY_URL = BASE_PSDN_URL + "/api/v1/agencies";

const TRACKJS_ENV = {
  token: process.env.VUE_APP_TRACKJS_TOKEN,
  application: process.env.VUE_APP_TRACKJS_APP,
  enabled: parseBool(process.env.VUE_APP_TRACKJS_ENABLED)
};

const expObj = Object.freeze({
  ENDORSEMENT_EBO_URL: BASE_PSDN_URL + "/api/v1/policies/endorsement/ebo",
  BASE_DTC_WIDGET_URL: BASE_DTC_WIDGET_URL,
  BASE_TRITON_UI_URL,
  AGENCY_URL,
  PSDN_UI_BASE_URL,
  AGENT_LOGIN: `${BASE_TRITON_UI_URL}/#/login`,
  GET_AGENCIES: BASE_TRITON_URL + "/api/v4/agent/seachagents",
  OVERRIDES_GET_SUBAGENCIES: BASE_PSDN_URL + "/api/v1/commissions",
  AGENCY_DETAILS_FOR_POLICY: BASE_PSDN_URL + "/api/v1/agencies/AgencyDetailsForPolicy/{{agentNo}}",
  SUBMIT_AUTOAGENCY_DOCS: BASE_PSDN_URL + "/api/v1/agenciesauto/DocumentsAndSignatures",
  AUTOAGENCY_DOCS: BASE_PSDN_URL + "/api/v1/agenciesauto/GetAgencyDocuments",
  UPSERT_AND_DELETE_PROFILE_NOTES: BASE_PSDN_URL + "/api/v1/agencies/{{agencyNo}}/note",
  GET_PROFILE_NOTES: BASE_PSDN_URL + "/api/v1/agencies/{{agencyNo}}/notes",
  UPDATE_PAYMENT_METHOD: BASE_PSDN_URL + "/api/v1/payments/Recurring/UpdatePaymentMethod",
  REMOVE_PAYMENT_METHOD: `${BASE_PSDN_URL}/api/v1/payments/DeleteRenewalPaymentMethod/{{policyNo}}/{{paymentMethod}}`,
  CREATE_SETUP_INTENT: BASE_PSDN_URL + "/api/v1/payments/createSetupIntent",
  CREATE_SETUP_INTENT_WITH_KEY: BASE_PSDN_URL + "/api/v1/payments/createSetupIntentWithKey",
  GET_SIGNORS: BASE_PSDN_URL + "/api/v1/agencies/AuthorizeSignor/List/{{agencyLicenseId}}",
  AUTHORIZE_SIGNOR: BASE_PSDN_URL + "/api/v1/agencies/AuthorizeSignor",
  NON_RENEW_POLICIES: BASE_PSDN_URL + "/api/v1/policies/renewal",
  GET_CANCEL_REWRITE_DATES: BASE_PSDN_URL + "/api/v1/policies/getCancelRewriteDates/{{policyNo}}",
  POST_SINGLE_LOCKBOX_UPDATE: BASE_PSDN_URL + "/api/v1/payments/ProcessLockBoxItem",
  GET_LOCKBOX_UNAPPLIED: BASE_PSDN_URL + "/api/v1/payments/LockBoxItems",
  CREATE_COMMISSION_STATEMENT: BASE_PSDN_URL + "/api/v1/commissions/statements",
  UPDATE_CLAIM_NOTE: BASE_PSDN_URL + "/api/v1/claim_notes/{{noteID}}",
  DELETE_CLAIM_NOTE: BASE_PSDN_URL + "/api/v1/claim_notes/{{noteID}}",
  CREATE_CLAIM_NOTE: BASE_PSDN_URL + "/api/v1/claim_notes",
  GET_ALL_CLAIMS_NOTES: BASE_PSDN_URL + "/api/v1/claim_notes?claimsId={{claimID}}",
  DOWNLOAD_OR_DELETE_CLAIM_DOCUMENT:
    BASE_PSDN_URL + "/api/v1/claims/{{claimID}}/documents/{{claimDocumentID}}",
  CLAIMS_DOCUMENTS_URL: BASE_PSDN_URL + "/api/v1/claims/{{claimID}}/documents",
  CREATE_CLAIM: BASE_PSDN_URL + "/api/v1/claims",
  GET_CLAIM_BY_ID: BASE_PSDN_URL + "/api/v1/claims/{{claimID}}",
  GET_ALL_CLAIMS: BASE_PSDN_URL + "/api/v1/claims/getclaimdata",
  RESEND_FAILED_NOTIFICATION:
    BASE_PSDN_URL + "/api/v1/notifications/retrylob/{{notificationId}}/{{recipientId}}",
  STOP_PAYMENT:
    BASE_PSDN_URL +
    "/api/v1/Payments/stopPayment?paymentId={{paymentIdHere}}&isMailSupress={{suppressBoolean}}",
  STOP_REFUND: BASE_PSDN_URL + "/api/v1/Payments/stopRefund?paymentId={{refundIdHere}}",
  PAYMENTS_GET_APPLY_PAYMENTS: BASE_PSDN_URL + "/api/v1/payments/getApplyPayments",
  PAYMENTS_APPLY_PAYMENT_TO_POLICY: BASE_PSDN_URL + "/api/v1/Payments/applyPayment",
  POST_MORATORIUM: BASE_PSDN_URL + "/api/v1/moratorium/event",
  GET_MORATORIUM_ACTIVE_EVENTS: BASE_PSDN_URL + "/api/v1/moratorium/events?activeOnly=true",
  GET_MORATORIUM_COUNTIES: BASE_PSDN_URL + "/api/v1/moratorium/counties",
  GET_MORATORIUM_EVENTS: BASE_PSDN_URL + "/api/v1/moratorium/events",
  PUT_FLAG_TOGGLE: BASE_PSDN_URL + "/api/v1/documents/SetSignatureFlag",
  GET_POLICY_NOTICE: BASE_PSDN_URL + "/api/v1/notifications/{{policyNo}}/notice/{{pdfPath}}",
  GET_POLICY_TEMPLATE:
    BASE_PSDN_URL + "/api/v1/notifications/{{policyNo}}/emailcontent/{{emailTemplate}}",
  GET_POLICY_NOTIFICATIONS: BASE_PSDN_URL + "/api/v1/notifications/{{policyNo}}",
  REINSTATE_POLICY: BASE_PSDN_URL + "/api/v1/policies/reinstate/{{policyNo}}",
  GET_POLICY_DOCUMENTS2: BASE_PSDN_URL + "/api/v2/documents/policy/{{policyNo}}/pdfs",
  GET_STATEMENT_BY_ID: BASE_PSDN_URL + "/api/v1/commissions/statements?statementId={{statementId}}",
  DELETE_ADJUSTMENT: BASE_PSDN_URL + "/api/v1/commissions/adjustments",
  POST_ADJUSTMENT: BASE_PSDN_URL + "/api/v1/commissions/adjustments",
  GET_COMMISSIONS_ADJUSTMENTS:
    BASE_PSDN_URL + "/api/v1/commissions/adjustments?statementId={{statementId}}",
  GET_COMMISSIONS_TRANSACTIONS:
    BASE_PSDN_URL + "/api/v1/commissions/transactions?statementId={{statementId}}",
  GET_STATEMENTS: BASE_PSDN_URL + "/api/v1/commissions/statements",
  TRANSFER_PAYMENT_URL:
    BASE_PSDN_URL + "/api/v1/payments/{{curPolicyNo}}/moveto/{{nextPolicyNo}}/{{amount}}",
  POST_CHECK_PAYMENT: BASE_PSDN_URL + "/api/v1/payments/{{policyNo}}",
  DELETE_POLICY_NOTE: BASE_PSDN_URL + "/api/v1/policies/{{policyNo}}/note",
  UPSERT_POLICY_NOTE: BASE_PSDN_URL + "/api/v1/policies/{{policyNo}}/note",
  GET_POLICY_NOTES: BASE_PSDN_URL + "/api/v1/policies/{{policyNo}}/notes",
  RESEND_POLICY_LINK: BASE_PSDN_URL + "/api/v1/policies/{{policyNo}}",
  DELETE_POLICY_DOCUMENTS: BASE_PSDN_URL + "/api/v1/documents/policy/{{policyNo}}/pdfs",
  GET_POLICY_DOCUMENTS: BASE_PSDN_URL + "/api/v1/documents/policy/{{policyNo}}/pdfs",
  SEARCH_AGENCY_URL: BASE_PSDN_URL + "/api/v1/agencies/",
  GET_LOCKBOX_INFO: BASE_PSDN_URL + "/api/v1/policies/GetBankInfo/",
  GET_AGENCY_LINK: BASE_PSDN_URL + "/api/v1/public/agencies/",
  GET_AGENCY_DOCS: BASE_PSDN_URL + "/api/v1/agencies/AgencyDocs/",
  AGENCY_DOCUMENT_UPLOAD: BASE_PSDN_URL + "/api/v1/agencies/UploadAgencyDocs/",
  BULK_UPLOAD_URL: BASE_PSDN_URL + "/api/v1/agencies/ImportJobs",
  GET_SIGNATURES_URL: BASE_PSDN_URL + "/api/v1/documents/signatures",
  GET_PDF_URL: BASE_PSDN_URL + "/api/v1/public/documents/pdf",
  GET_DOCUMENTS_URL: BASE_PSDN_URL + "/api/v1/documents/policy/{{policyNo}}",
  GET_TRANSACTIONS_URL: BASE_PSDN_URL + "/api/v1/policies/{{policyNo}}/policytransactions",
  CANCEL_REWRITE_URL: BASE_PSDN_URL + "/api/v1/policies/{{policyNo}}/cancelrewrite",
  GET_TRANSACTIONSANDSUMS_URL:
    BASE_PSDN_URL + "/api/v1/policies/{{policyNo}}/policyTransactionsAndSummary",
  CREATE_ENDORSE_URL: BASE_PSDN_URL + "/api/v1/policies/createEndorsementUrl",
  ENDORSE_POLICY_URL: BASE_PSDN_URL + "/api/v1/policies/{{policyNo}}/endorse",
  TRITON_SEARCH_POLICIES_URL: BASE_TRITON_URL + "/api/v4/agent/search",
  CHANGE_PASSWORD_URL: BASE_TRITON_URL + "/api/v3/auth/passwordChangeNoOriginal",
  GET_CONFIRMATION_URL: BASE_PSDN_URL + "/api/v1/policies/{{policyNo}}/getConfirmation",
  GET_CLIENT_SECRET: `${BASE_TRITON_URL}/api/v4/sessions/getClientSecretFromQuoteNumber`,
  GET_TRANSACTION_FEE: `${BASE_TRITON_URL}/api/v4/sessions/getTransactionFee`,
  GET_TRANSACTION_FEE_PSDN: `${BASE_PSDN_URL}/api/v1/payments/gettransactionfee`,
  PROCESS_PAYMENT: `${BASE_PSDN_URL}/api/v1/payments/ProcessPayment`,
  GET_DEFAULT_PAYMENT_METHOD: `${BASE_PSDN_URL}/api/v1/payments/GetDefaultOptInRenewalPaymentMethod/{{policyNo}}`,
  GET_LATEST_RECURRING_PAYMENT: `${BASE_PSDN_URL}/api/v1/payments/latestRecurringPayment/{{policyNo}}`,
  GET_LIENHOLDER_URL: BASE_PSDN_URL + "/api/v1/policies/{{policyNo}}/PolicyLiens",
  REQUEST_EMAIL_URL: BASE_PSDN_URL + "/api/v2/auth/policyholder/requestEmail",
  POSEIDON_LOGIN_URL: BASE_PSDN_URL + "/api/v2/auth/policyholder/login",
  POSEIDON_AGENT_LOGIN_URL: BASE_PSDN_URL + "/api/v2/auth/agent/login",
  SIGN_URL: BASE_PSDN_URL + "/api/v1/documents/signatures",
  PAY_ACH_URL: BASE_PSDN_URL + "/api/v1/payments/ach",
  PAY_CARD_URL: BASE_PSDN_URL + "/api/v1/payments/confirmPaymentIntent",
  INTENT_URL: BASE_PSDN_URL + "/api/v1/payments/intent",
  SAVED_PAYMENTMETHODS_URL: BASE_PSDN_URL + "/api/v1/payments/paymentmethods/{{policyNo}}",
  AGENCY_CONTACT_URL: BASE_PSDN_URL + "/api/v1/agencies/contact",
  AGENCY_CHANGE_PARENT_URL: BASE_PSDN_URL + "/api/v1/agencies/ChangeParentAgency",
  AGENCY_LOOKUP_TYPE_URL: BASE_PSDN_URL + "/api/v1/agencies/AgencyLookupData",
  AGENCY_CONTACTS_URL: BASE_PSDN_URL + "/api/v1/agencies/contacts/{{AgentNo}}",
  AGENCY_LICENSE_URL: BASE_PSDN_URL + "/api/v1/agencies/license",
  AGENCY_LICENSES_URL: BASE_PSDN_URL + "/api/v1/agencies/licenses/{{AgentNo}}",
  SUBAGENTS_URL: AGENCY_URL + "/SubAgenciesByParentAgencyNo/{{AgentNo}}",
  AGENCY_DETAIL_URL: AGENCY_URL + "/AgencyDetails/{{AgentNo}}",
  AGENCY_DETAIL_BY_ID_URL: AGENCY_URL + "/AgencyDetailsForPolicy/{{AgentNo}}",
  POLICY_COMMERCIAL_URL: BASE_PSDN_URL + "/api/v1/documents",
  UPLOAD_URL: BASE_PSDN_URL + "/api/v1/documents/policy/{{docType}}/{{policyNo}}",
  GET_TOKEN_URL: BASE_TRITON_URL + "/api/v4/auth/getToken",
  AUTHORIZE_TOKEN_URL: BASE_TRITON_URL + "/api/v4/auth/validate",
  REDEEM_RESOURCE_URL: BASE_TRITON_URL + "/api/v1/resource/redeem/{{resourceId}}",
  VERIFIED_EC_DOC: BASE_PSDN_URL + "/api/v1/documents/policydococr/{{verId}}",
  GET_ADDRESS_DATA: `${BASE_PSDN_URL}/api/v1/policies/address/{{addressID}}/version/{{addressDataVersion}}`,
  GET_BIND_COUNT: `${BASE_PSDN_URL}/api/v1/agencies/PolicyCount/{{agentCode}}`,
  AUTH_KEY: AUTHKEY,
  CONTENT_TYPE: "application/json",
  NEW_QUOTE_NO: "FL0000000",
  DEBUG,
  BASE_PSDN_URL,
  BASE_TRITON_URL,
  NEW_AGENT_URL,
  skipIndicators,
  WESTPOINT,
  ENVIRONMENT: ENV_DESCRIPTION,
  TrackJS: TRACKJS_ENV,
  ENV: ENV_DESCRIPTION,
  JUMPSTART_ENABLED: parseInt(process.env.VUE_APP_JUMPSTART_ENABLED),
  ENUMS: {
    PROPERTY_TYPES,
    FOUNDATION_TYPES,
    BILLING,
    PAYTMENT,
    DELIVERY,
    OCCUPANCY,
    STORIES,
    CONSTRUCTION_TYPE,
    NUMLOSS
  }
});

export const Constants = expObj;

// these are in order for worst zones to best zones
// users can select any zone above the current zone, but
// cannot select a zone below.  In other words, they can
// select a worse flood zone, but not a better one.
// SHOULD MOVE THESE INTO CONFIG
// eslint-disable-next-line
export const floodZones = Object.freeze([
  { zone: "V", rank: 1 },
  { zone: "A", rank: 2 },
  { zone: "AE", rank: 2 },
  { zone: "X", rank: 4 },
  { zone: "------", rank: 1, disabled: true },
  { zone: "V", rank: 1 },
  { zone: "VE", rank: 1 },
  { zone: "V1", rank: 1 },
  { zone: "V2", rank: 1 },
  { zone: "V3", rank: 1 },
  { zone: "V4", rank: 1 },
  { zone: "V5", rank: 1 },
  { zone: "V6", rank: 1 },
  { zone: "V7", rank: 1 },
  { zone: "V8", rank: 1 },
  { zone: "V9", rank: 1 },
  { zone: "V10", rank: 1 },
  { zone: "V11", rank: 1 },
  { zone: "V12", rank: 1 },
  { zone: "V13", rank: 1 },
  { zone: "V14", rank: 1 },
  { zone: "V15", rank: 1 },
  { zone: "V16", rank: 1 },
  { zone: "V17", rank: 1 },
  { zone: "V18", rank: 1 },
  { zone: "V19", rank: 1 },
  { zone: "V20", rank: 1 },
  { zone: "V21", rank: 1 },
  { zone: "V22", rank: 1 },
  { zone: "V23", rank: 1 },
  { zone: "V24", rank: 1 },
  { zone: "V25", rank: 1 },
  { zone: "V26", rank: 1 },
  { zone: "V27", rank: 1 },
  { zone: "V28", rank: 1 },
  { zone: "V29", rank: 1 },
  { zone: "V30", rank: 1 },
  { zone: "A", rank: 2 },
  { zone: "AE", rank: 2 },
  { zone: "AH", rank: 2 },
  { zone: "ANI", rank: 2 },
  { zone: "AO", rank: 2 },
  { zone: "AR", rank: 2 },
  { zone: "A1", rank: 2 },
  { zone: "A2", rank: 2 },
  { zone: "A3", rank: 2 },
  { zone: "A4", rank: 2 },
  { zone: "A5", rank: 2 },
  { zone: "A6", rank: 2 },
  { zone: "A7", rank: 2 },
  { zone: "A8", rank: 2 },
  { zone: "A9", rank: 2 },
  { zone: "A10", rank: 2 },
  { zone: "A11", rank: 2 },
  { zone: "A12", rank: 2 },
  { zone: "A13", rank: 2 },
  { zone: "A14", rank: 2 },
  { zone: "A15", rank: 2 },
  { zone: "A16", rank: 2 },
  { zone: "A17", rank: 2 },
  { zone: "A18", rank: 2 },
  { zone: "A19", rank: 2 },
  { zone: "A20", rank: 2 },
  { zone: "A21", rank: 2 },
  { zone: "A22", rank: 2 },
  { zone: "A23", rank: 2 },
  { zone: "A24", rank: 2 },
  { zone: "A25", rank: 2 },
  { zone: "A26", rank: 2 },
  { zone: "A27", rank: 2 },
  { zone: "A28", rank: 2 },
  { zone: "A29", rank: 2 },
  { zone: "A30", rank: 2 },
  { zone: "A99", rank: 2 },
  { zone: "B", rank: 3 },
  { zone: "C", rank: 3 },
  { zone: "D", rank: 3 },
  { zone: "X", rank: 4 },
  { zone: "X500", rank: 4 },
  { zone: "X500L", rank: 4 }
]);

export const deStates = [
  "CA",
  "CO",
  "DE",
  "FL",
  "GA",
  "HI",
  "IN",
  "IA",
  "KS",
  "ME",
  "MD",
  "MA",
  "MN",
  "MO",
  "NE",
  "NV",
  "NH",
  "NM",
  "NY",
  "NC",
  "OH",
  "OR",
  "SC",
  "TN",
  "TX",
  "WA"
];

export const applicationOptions = {
  billingOptions: [
    { text: "Insured", value: "insured" },
    { text: "Mortgagee Lienholder", value: "lienholder" }
  ],
  deliveryOptions: [
    { text: "Electronically", value: "electronic" },
    { text: "Print", value: "print" }
  ],
  paymentOptions: [
    { text: "ACH", value: "ach" },
    { text: "Credit Card *", value: "credit" },
    { text: "Check", value: "check" }
  ],
  mailingSameOptions: [
    { text: "No", value: false },
    { text: "Yes", value: true }
  ],
  stateList: [
    { value: "AL", text: "ALABAMA" },
    { value: "AK", text: "ALASKA" },
    { value: "AZ", text: "ARIZONA" },
    { value: "AR", text: "ARKANSAS" },
    { value: "CA", text: "CALIFORNIA" },
    { value: "CO", text: "COLORADO" },
    { value: "CT", text: "CONNECTICUT" },
    { value: "DE", text: "DELAWARE" },
    { value: "FL", text: "FLORIDA" },
    { value: "GA", text: "GEORGIA" },
    { value: "HI", text: "HAWAII" },
    { value: "ID", text: "IDAHO" },
    { value: "IL", text: "ILLINOIS" },
    { value: "IN", text: "INDIANA" },
    { value: "IA", text: "IOWA" },
    { value: "KS", text: "KANSAS" },
    { value: "KY", text: "KENTUCKY" },
    { value: "LA", text: "LOUISIANA" },
    { value: "ME", text: "MAINE" },
    { value: "MD", text: "MARYLAND" },
    { value: "MA", text: "MASSACHUSETTS" },
    { value: "MI", text: "MICHIGAN" },
    { value: "MN", text: "MINNESOTA" },
    { value: "MS", text: "MISSISSIPPI" },
    { value: "MO", text: "MISSOURI" },
    { value: "MT", text: "MONTANA" },
    { value: "NE", text: "NEBRASKA" },
    { value: "NV", text: "NEVADA" },
    { value: "NH", text: "NEW HAMPSHIRE" },
    { value: "NJ", text: "NEW JERSEY" },
    { value: "NM", text: "NEW MEXICO" },
    { value: "NY", text: "NEW YORK" },
    { value: "NC", text: "NORTH CAROLINA" },
    { value: "ND", text: "NORTH DAKOTA" },
    { value: "OH", text: "OHIO" },
    { value: "OK", text: "OKLAHOMA" },
    { value: "OR", text: "OREGON" },
    { value: "PA", text: "PENNSYLVANIA" },
    { value: "RI", text: "RHODE ISLAND" },
    { value: "SC", text: "SOUTH CAROLINA" },
    { value: "SD", text: "SOUTH DAKOTA" },
    { value: "TN", text: "TENNESSEE" },
    { value: "TX", text: "TEXAS" },
    { value: "UT", text: "UTAH" },
    { value: "VT", text: "VERMONT" },
    { value: "VA", text: "VIRGINIA" },
    { value: "WA", text: "WASHINGTON" },
    { value: "WV", text: "WEST VIRGINIA" },
    { value: "WI", text: "WISCONSIN" },
    { value: "WY", text: "WYOMING" },
    { value: "DC", text: "DISTRICT OF COLUMBIA" }
  ]
};

export const RewardLevels = [
  {
    name: "None",
    policies: {
      min: 0,
      max: 9
    },
    bar: {
      min: 0,
      max: 9
    }
  },
  {
    name: "Bronze",
    policies: {
      min: 10,
      max: 24
    },
    bar: {
      min: 10,
      max: 70
    }
  },
  {
    name: "Silver",
    policies: {
      min: 25,
      max: 49
    },
    bar: {
      min: 71,
      max: 119
    }
  },
  {
    name: "Gold",
    policies: {
      min: 50,
      max: 74
    },
    bar: {
      min: 120,
      max: 170
    }
  },
  {
    name: "Platinum",
    policies: {
      min: 75,
      max: 99
    },
    bar: {
      min: 171,
      max: 220
    }
  },
  {
    name: "Diamond",
    policies: {
      min: "100"
    },
    bar: {
      min: 221,
      max: 300
    }
  }
];

export default {
  expObj,
  Constants,
  floodZones,
  deStates,
  applicationOptions,
  RewardLevels
};
