import { Constants } from "@/config";
import axios from "axios";
import { stripeInstance } from "../store-module/modules/payments";
import { loadStripe } from "@stripe/stripe-js";
import { compareDesc } from "date-fns";

function get_error_from_array(arr) {
  if (arr?.length) {
    const [first_error] = arr;
    return first_error.Error ?? first_error;
  }
}

export function unwrap_error(e) {
  if (!e.response) {
    return e.message;
  }
  let { data } = e.response;
  if (typeof data === "string") {
    data = JSON.parse(data);
  }
  if (data.message) {
    return data.message;
  } else if (data.payload) {
    return "An unknown error occurred";
  }
  let message = get_error_from_array(data.errors);
  if (message) {
    return message;
  }
  message = get_error_from_array(data.errorList);
  if (message) {
    return message;
  }
}

export async function fetch_policy_confirmation({ authToken, policyNo }) {
  /** @type {ApiResponseWithPayload<neptune.policyholder.IPolicySummaryResponse>} */
  const {
    data: { payload: confirmationData }
  } = await axios.get(
    // `/api/v1/policies/${policyNo}/getconfirmation`
    Constants.GET_CONFIRMATION_URL.replace("{{policyNo}}", policyNo),
    {
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json"
      }
    }
  );

  return confirmationData;
}

export async function fetch_policy_transactions({ authToken, policyNo }) {
  /** @type {ApiResponseWithPayload<IPolicyTransaction[]>} */
  const {
    data: { payload: transactions }
  } = await axios.get(
    // `/api/v1/policies/${policyNo}/policyTransactions`
    Constants.GET_TRANSACTIONS_URL.replace("{{policyNo}}", policyNo),
    {
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json"
      }
    }
  );

  return transactions;
}

export async function fetch_policy_documents({ authToken, policyNo }) {
  /** @type {ApiResponseWithPayload<neptune.auth.INewAuthorizationDataPolicy>} */
  const {
    data: { payload: documents }
  } = await axios.get(
    // `/api/v1/documents/policy/${policyNo}`
    Constants.GET_DOCUMENTS_URL.replace("{{policyNo}}", policyNo),
    {
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json"
      }
    }
  );

  documents.policyDocStatus.documentsWithSignatures.forEach((d) => {
    d.signatures = d.signatures.filter((s) => !s.signatureImageId);
  });

  return documents;
}

export async function fetch_payment_methods({ authToken, policyNo }) {
  /** @type {ApiResponseWithPayload<neptune.payment.IPaymentMethod[]>} */
  const {
    data: { payload }
  } = await axios.get(
    // '/api/v1/payments/paymentmethods'
    Constants.SAVED_PAYMENTMETHODS_URL.replace("{{policyNo}}", policyNo),
    {
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json"
      }
    }
  );

  return payload;
}

export async function save_signatures({
  authToken,
  policyNo,
  documents: {
    policyDocStatus: { documentsWithSignatures }
  }
}) {
  const response = await axios.post(
    Constants.SIGN_URL,
    {
      policyNo,
      documentsWithSignatures,
      isPolicySigned: false,
      isPolicyPaid: false
    },
    {
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json"
      }
    }
  );

  return response?.data;
}

export async function make_ach_payment(context, event) {
  try {
    const bankToken = await get_token(event.data.tokenOptions);
    event.data.chargeOptions.BankToken = bankToken;
    const options = {
      BankToken: bankToken,
      PolicyNo: event.data.chargeOptions.PolicyNo,
      PayerName: event.data.chargeOptions.PayerName,
      SaveForReuse: event.data.SaveForReuse,
      Email: event.data.chargeOptions.Email,
      Description: event.data.chargeOptions.Description,
      CustomerToken: event.data.chargeOptions.CustomerToken
    };
    await charge_ach(options, event.data.axiosConfig);
  } catch (e) {
    const errorMessage = unwrap_error(e);
    throw new Error(errorMessage);
  }
}

/**
 * Gets the bank account token
 * @param {stripe.BankAccountTokenOptions} stripeOptions
 */
export async function get_token(stripeOptions) {
  const { token, error } = await stripeInstance.default.createToken("bank_account", stripeOptions);
  if (error) {
    return Promise.reject(error);
  }
  return token.id;
}

/**
 * post ACH data to the server and returns a stripe response
 * @param {neptune.payment.ach.IAchChargeParams} params
 */
export async function charge_ach(params, headers) {
  try {
    const data = await axios.post(
      Constants.PAY_ACH_URL, // 'https://dev-psdn-api.neptuneflood.com/api/v1/payments/ach',
      params,
      headers
    );
    if (!data) {
      throw new Error(
        "Please contact Neptune Flood along with this response: " +
          (data.response?.message || data.response || data.message)
      );
    }
    return data;
  } catch (e) {
    const errorMessage = unwrap_error(e);
    throw new Error(errorMessage);
  }
}

export async function make_cc_payment() {}

export async function load_stripe(context) {
  if (!context.confirmation?.stripeKey) {
    throw new Error("no stripeKey in confirmation");
  }

  return await loadStripe(context.confirmation.stripeKey);
}

export async function get_client_secret(context) {
  let quoteNumber;
  for (const t of context.transactions) {
    try {
      const meta = JSON.parse(t.meta);
      if (meta.quoteNumber) {
        quoteNumber = meta.quoteNumber;
        break;
      }
    } catch {
      continue;
    }
  }

  if (!quoteNumber) {
    throw new Error("quoteNumber required");
  }

  try {
    const response = await axios.post(
      Constants.GET_CLIENT_SECRET,
      {
        QuoteNumber: quoteNumber
      },
      {
        headers: {
          Authorization: context.authToken,
          "Content-Type": "application/json"
        }
      }
    );

    if (!response.data?.client_secret) {
      throw new Error(`unable to fetch client_secret from Stripe for quote ${context.quoteNumber}`);
    }

    return response.data;
  } catch (e) {
    console.log("e", e);
    throw new Error(e);
  }
}

export async function get_client_secret_triton(context) {
  const [transaction] = context.transactions.sort((a, b) => {
    return compareDesc(new Date(a.postDate), new Date(b.postDate));
  });

  const totalDue = context.confirmation.totalDue;
  const payload = {
    FirstName: transaction.firstName,
    LastName: transaction.lastName,
    Email: transaction.email,
    Phone: transaction.phone,
    Baid: transaction.baId,
    Amount: totalDue
  };

  const response = await axios.post(Constants.CREATE_SETUP_INTENT_WITH_KEY, payload, {
    headers: {
      Authorization: context.authToken,
      "Content-Type": "application/json"
    }
  });

  if (!response.data?.payload?.data?.client_secret) {
    throw new Error(
      `We are unable to collect payment at this time. If this issue persists please contact support.`
    );
  }

  return response.data.payload.data;
}

export async function get_transaction_fee(context) {
  const amount = getPaymentAmount(context);
  const { paymentMethod, authToken } = context;

  const url = new URL(Constants.GET_TRANSACTION_FEE);
  url.searchParams.set("amount", amount);
  url.searchParams.set("card", paymentMethod.card.brand);
  url.searchParams.set("country", paymentMethod.card.country);

  const response = await axios.get(url, {
    headers: {
      Authorization: authToken,
      "Content-Type": "application/json"
    }
  });

  return response.data;
}

export async function get_transaction_fee_triton(context) {
  const amount = getPaymentAmount(context);
  const { paymentMethod, authToken } = context;

  const url = new URL(Constants.GET_TRANSACTION_FEE_PSDN);
  url.searchParams.set("amount", amount);
  url.searchParams.set("card", paymentMethod.card.brand);
  url.searchParams.set("country", paymentMethod.card.country);

  const response = await axios.get(url, {
    headers: {
      Authorization: authToken,
      "Content-Type": "application/json"
    }
  });

  return response.data;
}

function getPaymentAmount(context) {
  const {
    confirmation: { currentlyDue, productId, totalDue, totalPremium }
  } = context;

  if (productId === 4 || productId === 5) {
    return Math.floor(currentlyDue);
  }

  return totalDue ?? totalPremium;
}

export async function confirm_setup_intent(context) {
  const confirmation = await context.stripe.confirmCardSetup(context.client_secret, {
    payment_method: context.paymentMethod.id
  });

  if (confirmation.error?.message) {
    throw new Error(confirmation.error.message);
  } else if (confirmation.error) {
    console.error("CONFIRM_SETUP_INTENT_ERROR", confirmation.error);
    throw new Error("An unexpected error occured");
  }

  return confirmation;
}

export async function confirm_ach(context, event) {
  const { setupIntent, error } = await context.stripe.confirmUsBankAccountSetup(
    context.client_secret,
    {
      payment_method: {
        billing_details: {
          name: event.data.name
        },
        us_bank_account: {
          account_number: event.data.account_number,
          routing_number: event.data.routing_number,
          account_holder_type: event.data.account_holder_type // 'individual' or 'company'
        }
      }
    }
  );

  if (error) {
    throw error;
  }

  return setupIntent;
}

export async function process_payment(context) {
  if (!context.paymentMethod?.id) {
    console.error("PROCESS_PAYMENT_ERROR", "paymentMethod is required but not found in context");
    throw new Error("no payment method found");
  }

  const { authToken, optInAutoPayRenewal, paymentMethod, policyNo } = context;

  const response = await axios.post(
    Constants.PROCESS_PAYMENT,
    {
      PolicyNo: policyNo,
      PaymentMethodId: paymentMethod.id,
      OptInAutoPayRenewal: Boolean(optInAutoPayRenewal)
    },
    {
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json"
      }
    }
  );

  return response.data;
}

export async function fetch_address_data(context) {
  const [tnx] = context.transactions.sort((a, b) => {
    return compareDesc(new Date(a.postDate), new Date(b.postDate));
  });
  let addressID = null;
  let addressDataVersion = null;
  try {
    const meta = JSON.parse(tnx.meta);
    addressID = meta?.addressID;
    addressDataVersion = meta?.addressDataVersion;
  } catch (e) {
    console.error(e);
  }

  if (!addressID || !addressDataVersion) {
    throw new Error("Address id and/or version missing");
  }

  const url = Constants.GET_ADDRESS_DATA.replace("{{addressID}}", addressID).replace(
    "{{addressDataVersion}}",
    addressDataVersion
  );

  const response = await axios.get(url, {
    headers: {
      Authorization: context.authToken,
      "Content-Type": "application/json"
    }
  });

  return response.data;
}
