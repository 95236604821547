/**
 * @typedef {neptune.IPayloadResponse<neptune.store.user.UserProfilePayload} ServerData
 */

import { authAxios } from "../auth";
import { Constants } from "@/config";
/** @type {import("vuex").ActionTree<neptune.store.user.UserProfilePayload, neptune.IRootState>} */
const actions = {
  async load({ commit, rootState }, agentNo) {
    try {
      /**
       * @type {import('axios').AxiosResponse<ServerData>}
       */
      const d = await authAxios.get(
        Constants.AGENCY_DETAIL_URL.replace(
          /\{\{AgentNo\}\}/gi,
          agentNo || rootState.auth?.data?.AgentId
        )
      );

      if (d.data?.payload?.errors?.length) {
        // debugger
        return Promise.reject(new Error(d.data.payload.errors.map((e) => e.message).join(", ")));
      }

      /**
       * ! ONLY STORE TO THE STORE IF CURRENT USER
       */
      if (d.data.payload.agencyInfo.agencyNo === rootState.auth.data.AgentId) {
        commit("SET_STATE", d.data.payload);
      }

      return d.data.payload;
    } catch (error) {
      throw new Error(error);
    }
  },

  /**
   * Loads agent data in order to receive the id
   *
   * @todo Remove the unnecessary step to get this data
   * @param {import('vuex').ActionContext} _ unused
   * @param {string} agentNo IE 'FL0008'
   */
  async getAgentId(_, agentNo) {
    try {
      /**
       * @type {import('axios').AxiosResponse<ServerData>}
       */
      const d = await authAxios.get(
        Constants.AGENCY_DETAIL_URL.replace(/\{\{AgentNo\}\}/gi, agentNo)
      );

      if (d.data?.payload?.errors?.length) {
        // debugger
        return Promise.reject(new Error(d.data.payload.errors.map((e) => e.message).join(", ")));
      }
      return d.data.payload?.agencyDetailInfo?.agencyId;
    } catch (error) {
      console.log({ ...error });
      debugger;
      return Promise.reject(new Error(error.response?.data?.message));
    }
  },

  /**
   * gets agent information by ID
   * ! This does not commit anything to the store
   * ! Only used for getting agent info for any policy
   */
  async loadById({ rootGetters }, agencyNo) {
    try {
      /**
       * @type {import('axios').AxiosResponse<ServerData>}
       */
      const d = await authAxios.get(
        Constants.AGENCY_DETAIL_BY_ID_URL.replace(
          /\{\{AgentNo\}\}/gi,
          agencyNo || rootGetters["policy/allData"].agencyNo
        )
      );
      // debugger

      return d.data.payload;
    } catch (error) {
      console.log({ ...error });
      // debugger
      return Promise.reject(new Error(error.response?.data?.message));
    }
  },

  async update({ state, rootState, commit, dispatch }, newState) {
    try {
      const stateData = newState || state;
      const payload = stateData;

      let transactionCancel;
      if (typeof transactionCancel === "function") {
        transactionCancel();
      }

      const res = await authAxios.post(`${Constants.AGENCY_URL}/update`, payload, {
        cancelToken: this.transactionCancel
      });

      if (res.data?.statusCode !== 200) {
        return Promise.reject(new Error(res.data.message));
      }

      /**
       * ! ONLY STORE TO THE STORE IF CURRENT USER
       */
      if (res.data.payload.agencyInfo.agencyNo === rootState.auth.data.AgentId) {
        commit("SET_STATE", res.data.payload);
      }
      // TODO: return the response payoad once it becomes the model
      await dispatch("load", res.data.payload.agencyInfo.agencyNo);
      return JSON.parse(JSON.stringify(res.data.payload));
    } catch (error) {
      const e = { ...error };

      if (e.isAxiosError) {
        if (e.response.status === 500 || e.response.status === 400) {
          return Promise.reject(
            new Error(
              JSON.stringify(
                e.response.data?.payload[0]?.message ||
                  e.response.data?.message ||
                  e.response.data ||
                  "Server responded with a " + e.response.status + " error."
              )
            )
          );
        } else {
          return Promise.reject(e);
        }
      }

      debugger;
      return Promise.reject(
        new Error(error.response?.data?.message ?? error.response?.data?.statusCode)
      );
    }
  },

  async loadSubAgents({ rootState }, agentNo) {
    const id = agentNo || rootState.auth.data.AgentId;

    const { data } = await authAxios.get(Constants.SUBAGENTS_URL.replace(/\{\{AgentNo\}\}/gi, id));

    return data.payload || [];
  },

  async loadContacts({ getters }) {
    const { data } = await authAxios.get(getters.contactsUrl);
    return data.payload?.data;
  },
  async loadLicenses({ getters }) {
    const { data } = await authAxios.get(getters.licensesUrl);
    return data.payload?.data;
  },
  // eslint-disable-next-line no-unused-vars
  async postBulkUploads({ getters }, { file, notifEmail }) {
    try {
      const formData = new FormData();
      formData.append("", file[0], file[0].name);
      const response = await authAxios.post(
        `${Constants.BULK_UPLOAD_URL}?notificationEmail=${notifEmail}`,
        formData
      );
      return response.data.payload.data;
    } catch (err) {
      return Promise.reject(new Error(err));
    }
  },
  async fetchImportJobs() {
    const response = await authAxios.get(Constants.BULK_UPLOAD_URL);
    return response.data.payload.data || [];
  },

  // eslint-disable-next-line no-unused-vars
  async fetchImportJobItems({ getters }, id) {
    const response = await authAxios.get(`${Constants.BULK_UPLOAD_URL}Items/${id}`);
    return response.data.payload.data || [];
  },
  // eslint-disable-next-line no-unused-vars
  async postAgencyDocument({ getters }, { file, agencyNo, docTypeId, name }) {
    const formData = new FormData();
    formData.append(`${name}`, file);

    const response = await authAxios.post(
      `${Constants.AGENCY_DOCUMENT_UPLOAD}${agencyNo}/${docTypeId}`,
      formData,
      { headers: { "content-type": "multipart/formdata" } }
    );
    if (response?.data?.payload?.errors?.length) {
      const errThrown = response.data.payload.errors[0];
      if (errThrown.message.includes("The specified blob already exists"))
        throw new Error(`Document has already been uploaded.`);
      throw new Error(errThrown.message);
    }
    return response.data.payload.data || [];
  },
  // eslint-disable-next-line no-unused-vars
  async getAgencyDocuments({ getters }, agencyId) {
    const response = await authAxios.get(`${Constants.GET_AGENCY_DOCS}${agencyId}`);
    return response.data.payload.data;
  },
  // eslint-disable-next-line no-unused-vars
  async getAgencyDocumentsLink({ getters }, { link, name }) {
    const response = await authAxios
      .get(`${Constants.GET_AGENCY_LINK}doc?link=${link}`)
      .then((res) => {
        const link = document.createElement("a");
        link.setAttribute("href", `${res.config.url}`);
        link.setAttribute("target", "_");
        link.click();
        URL.revokeObjectURL(link.href);
      });
    return response;
  },
  // eslint-disable-next-line no-unused-vars
  async deleteDocumentAxios({ getters }, obj) {
    const body = { ...obj, documentName: "" };
    const response = await authAxios.delete(`${Constants.GET_AGENCY_DOCS}`, { data: body }).then();
    return response;
  },

  async changeParents({ state }, parentAgentNo) {
    const result = await authAxios.post(Constants.AGENCY_CHANGE_PARENT_URL, {
      NewParentAgentNo: parentAgentNo,
      ChildAgentNo: state.agencyInfo.agencyNo
    });

    return result.data;
  },

  // eslint-disable-next-line no-unused-vars
  async searchAgency({ getters }, companyName) {
    const result = await authAxios.get(
      `${Constants.SEARCH_AGENCY_URL}search?searchParam=${companyName}&skip=${0}&take=${1000}`
    );
    return result.data.payload.data;
  },

  async getPolicyCount ({ commit }, agentNo) {
    const url = new URL(Constants.GET_BIND_COUNT.replace("{{agentCode}}", agentNo));
    const response = await authAxios.get(url);

    commit("SET_POLICY_COUNT", response?.data?.payload?.policyCount);
  }
};

export default actions;
