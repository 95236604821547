var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"map-display"},[_c('div',{staticClass:"map-display__inner"},[(!_vm.loading)?_c('gmap-map',{ref:"gmap",staticStyle:{"width":"100%","height":"450px"},attrs:{"data-test":"manualAdd.map","center":_vm.mapCenter,"zoom":_vm.zoomLevel,"tilt":0,"map-type-id":"hybrid","options":{
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: true
      }},on:{"hook:mounted":_vm.onMapMounted}},[(!_vm.polygon && _vm.hasCoordinates)?_c('gmap-marker',{attrs:{"data-test":"manualAdd.pin","pre":"addressMark","position":_vm.coordinates,"draggable":false}}):_vm._e(),(_vm.polygon)?_c('gmap-polygon',_vm._b({attrs:{"draggable":false,"editable":false,"options":{
          fillColor: '#28bdbd',
          strokeColor: '#28bdbd'
        }}},'gmap-polygon',{
          [_vm.isMultiPolygon ? 'paths' : 'path']: _vm.polygon
        },false)):_vm._e()],1):_vm._e()],1),(!_vm.loading && !_vm.hasCoordinates && !_vm.polygon)?_c('div',{staticClass:"text-center bg-white flex flex-col justify-center items-center absolute top-0 bottom-0"},[_c('svg',{staticClass:"mx-auto h-12 w-12 text-gray-400",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor","stroke-width":"2"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"}})]),_c('h3',{staticClass:"mt-2 text-sm font-medium text-gray-900"},[_vm._v("Could not display map")]),_c('p',{staticClass:"mt-1 text-sm text-gray-500"},[_vm._v(" We were unable to retrieve the coordinates for this location ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }