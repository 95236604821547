import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const state = () => ({
  agencyInfo: {
    agencyId: 0,
    agencyNo: "",
    createdAt: "",
    createdByUserId: null,
    updatedAt: "",
    updatedByUserId: null,
    agencyDataId: null
  },
  agencyDetailInfo: {
    agencyDataId: 0,
    agencyId: 0,
    createdAt: "",
    type: 0,
    taxId: "",
    status: "A",
    company: "",
    addr1: "",
    addr2: "",
    city: "",
    state: "",
    zip: null,
    phone: null,
    fax: null,
    email: null,
    notes: null,
    mailingAddr1: "",
    mailingAddr2: "",
    mailingCity: "",
    mailingState: "",
    mailingZip: "",
    primaryContactId: null,
    agentParentId: null,
    bankRemoteReference: "",
    bankName: "",
    bankAddr1: "",
    bankAddr2: "",
    bankCity: "",
    bankState: "",
    bankZip: "",
    bankPhone: null,
    bankContact: null,
    bankEmail: null,
    bankNotes: null,
    bankRoutingNo: null,
    bankAccountNo: null,
    eoInsurer: "",
    eoPolicyNumber: "",
    eoLimit: 10000000,
    eoExpiration: "2019-04-01T00:00:00"
  },
  agencyContactInfo: [],
  agencyLicenseInfo: [],
  policyCount: null
});

export default {
  state,
  namespaced: true,
  actions,
  mutations,
  getters
};
