<template>
  <div class="policy-holder-summary !min-w-[270px] !flex-shrink-0" @click.right.prevent="showRaw">
    <keep-alive>
      <div v-if="raw">
        <el-input
          v-model="filter"
          clearable
          size="mini"
          class="mb-3"
          placeholder="search (regex available)"
          @keydown.native.esc="filter = ''"
        />
        <pre> {{ filtered }}</pre>
      </div>

      <div v-else class="content-wrapper">
        <!-- Property Info -->
        <div class="property !mb-0">
          <div class="map-container" data-test="summaryMap">
            <GoogleMapDisplay
              :loading="isAddressDataLoading"
              :address="fullAddress"
              :coordinates="coordinates"
              :polygon="polygon"
              :disabled="$readonly"
              data-test="mapDisplay__policyholder-summary"
            />
          </div>
          <div class="address">
            <h3 data-test="summaryAddrA">
              {{ addressObj.addr1 | lowerCase | startCase }}
              {{ addressObj.addr2 | lowerCase | startCase }}
            </h3>
            <p data-test="summaryAddrB" class="text-gray-500">
              {{ addressObj.city | lowerCase | startCase }}, {{ addressObj.state }}
              {{ addressObj.zip }}
            </p>
          </div>
          <el-button
            type="primary"
            icon="el-icon-map-location"
            data-test="policyViewButton"
            :class="{
              buttonOverride: $isEarthquake,
              '!bg-deepsea-full !border !border-deepsea-medium hover:!bg-deepsea-full/75': !$isEarthquake
            }"
            class="!mb-2"
            @click="viewPolicy()"
          >
            View Policy
          </el-button>
        </div>

        <!-- Price Detail -->
        <div class="totals">
          <div class="text-base bg-gray-200 px-2 py-1 rounded text-gray-600 -mx-2 font-bold mb-2">
            Premium
          </div>
          <div class="info-items space-y-2">
            <div
              v-for="(item, key) in summaryItems"
              :key="key"
              class="flex justify-between space-x-2 text-sm"
            >
              <span>{{ key | startCase }}</span>

              <span :data-test="`summaryPremium-${key}`" class="font-bold">{{
                item | currency
              }}</span>
            </div>

            <!-- Total -->
            <div class="bg-lightair-light rounded p-2 -mx-2 text-deepsea-full space-y-1">
              <div class="flex justify-between space-x-2 text-base">
                <span>Total Premium</span>
                <span class="font-bold" data-test="summaryTotalPremium">{{
                  finalTotalPremium | currency
                }}</span>
              </div>
              <div v-if="pendingTotalPremium" class="flex justify-between space-x-2 text-base ">
                <span>Balance Due</span>
                <span class="font-bold" data-test="summaryBalanceDue">{{
                  model.context.confirmation.totalDue | currency
                }}</span>
              </div>
              <div v-else class="flex justify-between space-x-2 text-base ">
                <span v-if="monthlyPay">Current Amount Due</span>
                <span v-else>Balance Due</span>
                <span v-if="monthlyPay">{{
                  model.context.confirmation.monthlyCost | currency
                }}</span>

                <span v-else class="font-bold" data-test="summaryBalanceDue">{{
                  model.context.confirmation.totalDue | currency
                }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="info-items">
          <div class="text-base bg-gray-200 px-2 py-1 rounded text-gray-600 -mx-2 font-bold mb-2">
            Property Info
          </div>
          <div class="space-y-2">
            <div
              v-for="(item, key) in propertyInfoItems"
              :key="key"
              class="flex justify-between space-x-2 text-sm"
            >
              <span>{{ key | startCase }}</span>

              <span :data-test="`summaryPropInfo-${key}`" class="font-bold text-right">{{
                item
              }}</span>
            </div>
          </div>
        </div>

        <!-- Coverage Info -->
        <div class="coverage">
          <div class="text-base bg-gray-200 px-2 py-1 rounded text-gray-600 -mx-2 font-bold mb-2">
            Coverages
          </div>

          <div class="info-items space-y-2">
            <div
              v-for="(item, key) in coverageItems"
              :key="key"
              class="flex justify-between space-x-2 text-sm"
            >
              <span>{{ key | startCase }}</span>

              <span :data-test="`summaryCoverages-${key}`" class="font-bold">{{
                item | currency
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </keep-alive>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import startCase from "lodash/startCase";
import { NumberFiltersMixin, DateFiltersMixin } from "../utils/mixins/formatting";
import GoogleMapDisplayVue from "@/components/routes/policy-details/GoogleMapDisplay.vue";
import { ascending } from "d3-array";
import { wktToPolygon } from "@/store/modules/policy/utils.js";

export default {
  components: {
    GoogleMapDisplay: GoogleMapDisplayVue
  },
  filters: {
    startCase: startCase,
    lowerCase: (s) => s.toLowerCase()
  },
  mixins: [NumberFiltersMixin, DateFiltersMixin],
  props: {
    /** @type {() => neptune.IPolicyData} */
    model: {
      type: Object,
      required: true
    },
    isModal: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    /**
     * @type {boolean}
     */
    raw: false,
    filter: null
  }),
  computed: {
    ...mapGetters("policy", ["isAddressDataLoading"]),
    monthlyPay() {
      return this.$store?.state?.policy?.confirmationData?.paymentInterval === "Monthly";
    },
    pendingTotalPremium() {
      return this.$store.getters["policy/allData"]?.pendingTotalPremium;
    },
    showViewButton() {
      return (
        this.isModal &&
        ((this.model.context.confirmation.isSigned[1]?.isSigned &&
          this.model.context.party === 2) ||
          !this.requiresSigs)
      );
    },
    requiresSigs() {
      const signatureStatus = this.model.context.confirmation.isSigned.find(
        (s) => s.party === this.model.context.party
      );
      const { requiresSignatures } = signatureStatus;
      return requiresSignatures;
    },
    previousUnpaid() {
      return this.model.context.confirmation.invoices.previousAmountPaid > 0;
    },
    policyNo() {
      return this.model.context.policyNo;
    },
    email() {
      return this.model.context.confirmation.email;
    },
    fullAddress() {
      const { addr1, addr2, city, state, zip } = this.model.context.transactions[
        this.model.context.transactions.length - 1
      ];

      return `${addr1} ${addr2}, ${city} ${state} ${zip}`;
    },
    addressObj() {
      const { addr1, addr2, city, state, zip } = this.model.context.transactions[
        this.model.context.transactions.length - 1
      ];
      return {
        addr1,
        addr2,
        city,
        state,
        zip
      };
    },
    totals() {
      const { totalPremium, taxes, policyFee, adjustments } = this.model.context.confirmation;

      return {
        policyFee,
        taxes,
        adjustments,
        totalPremium
      };
    },

    propertyInfoItems() {
      // policyData is from validateReturn
      // allData is from getConfirmation
      const policy =
        this.$store.getters["policy/policyData"] ?? this.$store.getters["policy/allData"];
      const {
        yearBuilt,
        floodZone,
        // propertyTypeDesc,
        constructionDesc,
        foundationTypeDesc,
        // steps,
        effectiveDate,
        stories,
        zip,
        censusBlock,
        occupancyTypeDesc
      } = this.model.context.transactions[this.model.context.transactions.length - 1];

      const { policyNo } = this.model.context;

      if (policy.productId === 4 || policy.productId === 5) {
        return {
          "Policy Number": policyNo,
          effectiveDate: DateFiltersMixin.filters.shortDate(effectiveDate),
          zip,
          censusBlock
        };
      } else {
        const foundationMap = {
          slab: "Slab",
          crawlspaceElevated: "Elevated crawlspace",
          crawlspaceSubgrade: "Subgrade crawlspace",
          basement: "Basement",
          elevatedOnPostPilesPiers: "Elevated without enclosure on posts, piles, or piers",
          elevatedWithEnclosureOnPostPilesPiers:
            "Elevated with enclosure on posts, piles, or piers",
          elevatedWithEnclosure: "Elevated with enclosure not on posts, piles, or piers"
        };
        return {
          "Policy Number": policyNo,
          yearBuilt,
          floodZone,
          // propertyType:    startCase(propertyTypeDesc.toLowerCase())
          //   .replace(/it is a/gi, '')
          //   .trim(),
          construction: constructionDesc,
          foundation: foundationMap[foundationTypeDesc] || foundationTypeDesc,
          // '# Steps':     steps,
          "# Stories": this.storiesToFriendlyName(
            stories,
            policy.productId,
            occupancyTypeDesc ?? this.$store.state.policy.transactionSummary.occupancyTypeDesc
          ),
          effectiveDate: DateFiltersMixin.filters.shortDate(effectiveDate)
        };
      }
    },

    coverageItems() {
      const sortedTransactions = [...this.model.context.transactions].sort((a, b) =>
        new Date(a.postDate) > new Date(b.postDate) ? 1 : -1
      );
      const latestTransaction = sortedTransactions[sortedTransactions.length - 1];
      const {
        coverageALimit,
        coverageBLimit,
        coverageDLimit,
        coverageELimit,
        coverageFLimit,
        coverageGLimit,
        coverageHLimit,
        coverageILimit,
        coverageJLimit,
        coverageKLimit,
        coverageLLimit,
        deductible,
        foundationTypeDesc,
        occupancyTypeDesc,
        propertyTypeDesc
      } = latestTransaction;

      let resItems = {
        buildings: coverageALimit,
        "Property Contents": coverageBLimit,
        "Replacement Cost Contents": coverageELimit === 1 ? "Yes" : "No",
        basementContents: coverageFLimit,
        poolRepairAndRefill: coverageGLimit,
        unattachedStructures: coverageHLimit,
        temporaryLivingExpenses: coverageILimit
      };
      if ([6].includes(latestTransaction.productId))
        resItems = {
          "Excess Buildings": coverageALimit,
          "Excess Property Contents": coverageBLimit,
          "Replacement Cost Contents": coverageELimit === 1 ? "Yes" : "No",
          "Basement Contents": coverageFLimit,
          "Pool Repair & Fill": coverageGLimit,
          "Unattached Structures": coverageHLimit,
          "Temporary Living Expenses": coverageILimit
        };

      if (
        latestTransaction.propertyTypeDesc === "IT IS A RENTAL AND I AM THE OWNER" &&
        !this.$store.state.policy.confirmationData.policyNo.includes("RNR")
      ) {
        if ([6].includes(latestTransaction.productId)) {
          delete resItems["Temporary Living Expenses"];
          resItems["Loss of Rental Income"] = coverageILimit;
        }
        if ([1].includes(latestTransaction.productId)) {
          delete resItems.temporaryLivingExpenses;
          resItems["Loss of Rental Income"] = coverageILimit;
        }
      }

      let commItems = {
        buildings: coverageALimit,
        "Property Contents": coverageBLimit
      };

      if ([7, 8].includes(latestTransaction.productId))
        commItems = {
          "Excess Buildings": coverageALimit,
          "Excess Property Contents": coverageBLimit
        };
      if (![1, 6].includes(this.$store.getters["policy/productNo"])) {
        if (
          ["basement", "commercialHasBasement", "hasBasement", "Basement"].includes(
            foundationTypeDesc
          )
        ) {
          commItems["Basement Contents"] = coverageFLimit;
        }
      }
      if (![1, 6].includes(this.$store.getters["policy/productNo"])) {
        if (
          occupancyTypeDesc !== "CommercialTenant" &&
          propertyTypeDesc !== "IT IS A RENTAL AND I AM THE TENANT" &&
          occupancyTypeDesc !== "Condo Which Floor"
        )
          commItems["Pool Repair & Fill"] = coverageGLimit;
      }

      const earthItems = {
        "Earthquake Coverage": coverageDLimit
      };
      if (latestTransaction.productId === 2 && coverageILimit > 0) {
        commItems["Business Interruption"] =
          coverageILimit === 1 ? "Up to $25,000" : coverageILimit;
      } else if (latestTransaction.productId === 2 && coverageJLimit > 0) {
        commItems["Rental Interruption"] = coverageJLimit;
      }

      if ([2, 7, 8].includes(latestTransaction.productId) && coverageILimit !== 0) {
        commItems["Business Interruption"] =
          coverageILimit === 1 ? "Up to $25,000" : coverageILimit;
      } else if (coverageJLimit > 0) {
        commItems["Rental Interruption"] = coverageJLimit;
      }

      if ([2, 7].includes(latestTransaction.productId)) {
        commItems["Replacement Cost Building"] = coverageKLimit === 1 ? "Yes" : "No";
      }
      if (
        propertyTypeDesc === "IT IS A SECONDARY OR SEASONAL HOME" &&
        [1, 6].includes(latestTransaction.productId)
      ) {
        resItems["Replacement Cost Building"] = coverageKLimit === 1 ? "Yes" : "No";
      }
      resItems.Deductible = deductible;
      commItems.Deductible = deductible;

      if (
        ([1, 6].includes(latestTransaction.productId) &&
          propertyTypeDesc === "IT IS A RENTAL AND I AM THE TENANT") ||
        ([2, 7].includes(latestTransaction.productId) && occupancyTypeDesc === "CommercialTenant")
      ) {
        resItems["Building Betterments and Improvements"] = coverageLLimit === 1 ? "Yes" : "No";
        commItems["Building Betterments and Improvements"] = coverageLLimit === 1 ? "Yes" : "No";
      }

      if ([1, 6].includes(latestTransaction.productId)) {
        return resItems;
      } else if ([2, 3, 7, 8].includes(latestTransaction.productId)) {
        return commItems;
      } else {
        return earthItems;
      }
    },
    finalTotalPremium() {
      const { transactions } = this.model.context;

      if (transactions.length === 1) {
        return transactions[this.model.context.transactions.length - 1].totalPremium;
      } else {
        return transactions.reduce((acc, trans) => (acc += trans.totalPremium), 0);
      }
    },
    previousTotalPremium() {
      const { transactions } = this.model.context;

      if (transactions.length === 1) {
        return transactions[this.model.context.transactions.length - 1].totalPremium;
      } else {
        return transactions.slice(0, -1).reduce((acc, trans) => (acc += trans.totalPremium), 0);
      }
    },
    summaryItems() {
      const { policyFee, grossPremium, taxes } = this.model.context.confirmation.invoices;

      return {
        premium: grossPremium === 0 ? "$0.00" : grossPremium,
        policyFee: policyFee === 0 ? "$0.00" : policyFee,
        taxes: taxes === 0 ? "$0.00" : taxes
      };
    },

    // TODO: remove
    filtered() {
      if (!this.filter) {
        return this.model.context;
      }

      /** @type {RegExp} */
      const reg = new RegExp(this.filter, "gi");

      const entries = Object.entries(this.model.context).filter(
        (k) => reg.test(k[0]) || reg.test(k[1])
      );
      const keys = entries.map((v) => v[0]);
      // Sort them
      keys.sort(ascending);

      return keys.reduce((prev, cur) => {
        prev[cur] = this.model.context[cur];

        return prev;
      }, {});
    },
    polygon() {
      if (!this.model?.context?.addressData?.polygon) {
        return null;
      }

      return wktToPolygon(this.model?.context?.addressData?.polygon);
    },
    coordinates() {
      return this.model?.context?.addressData?.geocode || {};
    }
  },
  methods: {
    storiesToFriendlyName(stories, productNo, occupancy) {
      switch (productNo) {
        case 1:
        case 6:
          if (occupancy !== "Condo Which Floor" && stories === 3) {
            return "3 or more";
          } else if (occupancy === "Condo Which Floor" && stories === 4) {
            return "4 or more";
          } else {
            return stories;
          }
        case 2:
        case 3:
        case 7:
        case 8:
          if (occupancy !== "CommercialTenant" && stories === 100) {
            return "100 or more";
          } else if (occupancy === "CommercialTenant" && stories === 4) {
            return "4 or more";
          } else if (occupancy === "CommercialOwnerSingleTenant" && stories === 3) {
            return "3 or more";
          } else {
            return stories;
          }
        default:
          return stories;
      }
    },
    viewPolicy() {
      if (this.$route.fullPath.includes("/policyholder/welcome")) {
        this.$router.push({
          name: "policyHolderDetail",
          params: {
            policyNo: this.policyNo
          }
        });
      } else {
        this.$emit("clickToClose");
      }
    },
    /**
     * @type {() => void}
     */
    showRaw() {
      if (process.env.NODE_ENV === "development") {
        this.raw = !this.raw;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.policy-holder-summary {
  position: relative;
}

.map-container {
  margin: 0 !important;
  box-shadow: 0 0 15px 0 rgba(#000, 0.5);
  border: 2px solid #fff;
  height: 160px;

  ::v-deep .map-display {
    height: 100%;
    padding: 0;
  }
}

.content-wrapper {
  display: flex;
  flex-flow: column;

  > * {
    display: flex;
    flex-flow: column;
    margin-bottom: 1.5rem;

    h2 {
      color: lighten(#000, 5);
      font-size: 1.7rem;
      margin-bottom: 0;
      line-height: 2.4rem;
    }
  }

  .address {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
    margin: 1rem 0 1.5rem;
  }

  .info-items {
    font-size: 0.8rem;
    line-height: 1.2rem;
  }

  .info-item {
    display: flex;
    flex-flow: column;
    margin-bottom: 0.5rem;
    white-space: nowrap;

    &.total {
      font-size: 1.5em;
    }

    @media screen and (min-width: 800px) {
      flex-flow: row;
      align-items: baseline;
      border-bottom: 1px solid #cecece;
      line-height: 1.5rem;

      &.total {
        border: 0;
        margin-top: 2rem;

        > * {
          &:nth-child(2) {
            color: #3f51b5;
            font-size: 1.5rem;
          }
        }
      }

      > * {
        &:first-child {
          font-weight: bold;
          margin-right: 1rem;
        }

        &:nth-child(2) {
          margin-left: auto;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 1.2em;
          font-weight: bold;
          color: #3f51b5;
        }
      }
    }
  }
}

pre {
  font-size: 0.7rem;
}
.buttonOverride {
  color: rgb(255, 97, 76);
  background: white;
  border: 1px solid rgb(255, 97, 76);
  text-shadow: none;
  &:hover,
  &:active,
  &:focus {
    filter: tint(75%);
    color: white;
  }
}
</style>
