import { globalMutations } from "@/components/policy-wizard/store-module/mutations";

/** @type {import("vuex").MutationTree} */

export default {
  ...globalMutations,
  SET_POLICY_COUNT(state, value) {
    state.policyCount = value;
  }
 };
