<template>
  <div>
    <div v-if="policies.length > 0" class="flex flex-col">
      <div class="-my-2 -mx-4 sm:-mx-6 lg:-mx-8 overflow-x-auto">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <table class="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                  data-test="history.policyTypeTitle"
                >
                  Policy Type
                </th>
                <th
                  scope="col"
                  class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                  data-test="history.policyNumberTitle"
                >
                  Policy Number
                </th>
                <th
                  scope="col"
                  class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                  data-test="history.inceptionDateTitle"
                >
                  Inception Date
                </th>
                <th
                  scope="col"
                  class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                  data-test="history.premiumTitle"
                >
                  Premium
                </th>
                <th
                  scope="col"
                  class="relative py-3.5 pl-3 pr-4 sm:pr-6 md:pr-0 text-left font-semibold text-gray-900 text-sm"
                  data-test="history.statusTitle"
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <tr
                v-for="policy in filteredPolicies"
                :key="policy.id"
                :class="{
                  'bg-deepsea-full/10':
                    !$isEarthquake && policy.policyNo === $route.params.policyNo,
                  'bg-orange-50/70': $isEarthquake && policy.policyNo === $route.params.policyNo
                }"
                :data-test="`history.row-${policy.policyNo}`"
              >
                <td
                  class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0"
                  :data-test="`history.productType-${policy.policyNo}`"
                >
                  <span v-if="[1, 2, 3].includes(policy.productId)">Flood</span>
                  <span v-else-if="[4, 5].includes(policy.productId)">Earthquake</span>
                  <span v-else-if="[6, 7, 8].includes(policy.productId)">Excess Flood</span>
                </td>
                <td
                  class="whitespace-nowrap py-4 px-3 text-sm text-gray-500"
                  :data-test="`history.policyNo-${policy.policyNo}`"
                >
                  <a
                    v-if="!isInsured"
                    :href="Constants.PSDN_UI_BASE_URL + '/#/policy/embed/' + policy.policyNo"
                    target="_blank"
                    :class="{
                      'bg-deepsea-full/10 text-deepsea-full hover:bg-deepsea-full/20 hover:!no-underline': !$isEarthquake,
                      'bg-brand-orange/10 text-brand-orange hover:bg-brand-orange/20 hover:!no-underline': $isEarthquake
                    }"
                    class="text-white font-bold text-center px-3.5 py-2.5 rounded-md min-w-[80px] text-sm "
                    >{{ policy.policyNo }}</a
                  >
                  <router-link v-else :to="'/policyholder/policies/' + policy.policyNo">
                    {{ policy.policyNo }}
                  </router-link>
                </td>
                <td
                  class="whitespace-nowrap py-4 px-3 text-sm text-gray-500"
                  :data-test="`history.inception-${policy.policyNo}`"
                >
                  {{ format(parseISO(policy.inceptionDate), "MM/dd/yyyy") }}
                </td>
                <td
                  class="whitespace-nowrap py-4 px-3 text-sm text-gray-500"
                  :data-test="`history.premium-${policy.policyNo}`"
                >
                  {{ policy.totalPremium | currency }}
                </td>
                <td
                  class="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm text-gray-500 sm:pr-6 md:pr-0"
                  :data-test="`history.status-${policy.policyNo}`"
                >
                  <span v-if="policy.isExpired">Expired</span>
                  <span v-else-if="policy.isCancelled">Cancelled</span>
                  <span v-else-if="Date.now() >= Date.parse(policy.inceptionDate)">Active</span>
                  <span v-else-if="Date.now() < Date.parse(policy.inceptionDate)">Future</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="bg-white sm:flex items-center justify-between border-t border-gray-200 pt-2">
        <div class="flex-1 flex justify-between sm:hidden mb-4">
          <a
            href="#"
            class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            data-test="history.previousButton"
          >
            Previous
          </a>
          <a
            href="#"
            class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            data-test="history.nextButton"
          >
            Next
          </a>
        </div>
        <div class="sm:flex-1 flex items-center justify-between">
          <div>
            <p class="text-sm text-gray-700">
              Showing
              <span class="font-medium">{{ rowBeginning }}</span>
              to
              <span class="font-medium">{{ rowEnd }}</span>
              of
              <span class="font-medium">{{ rowTotal }}</span>
              results
            </p>
          </div>
          <div class="flex items-center">
            <svg
              v-if="loading"
              class="animate-spin mr-3 h-5 w-5 text-sky-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <nav
              class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
              aria-label="Pagination"
            >
              <!-- first button -->
              <button
                class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-400 hover:!bg-gray-50"
                :class="{ 'opacity-50': page === 1 }"
                :disabled="page === 1"
                @click="
                  () => {
                    page = 1;
                    pageStart = 1;
                  }
                "
              >
                <span class="sr-only">Previous</span>
                <!-- Heroicon name: solid/chevron-double-left -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>

              <!-- prev button -->
              <button
                :class="{ 'opacity-50': page === 1 }"
                :disabled="page === 1"
                class="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium border-gray-300 text-gray-400 hover:!bg-gray-50 cursor"
                @click="
                  () => {
                    page -= 1;
                    if (!availablePages.includes(page)) pageStart -= 5;
                  }
                "
              >
                <!-- Heroicon name: solid/chevron-left -->
                <svg
                  class="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>

              <!-- page numbers -->
              <button
                v-for="n in availablePages"
                :key="n"
                aria-current="page"
                class="z-10 relative inline-flex items-center px-3 py-2 border text-sm font-medium"
                :class="{
                  'bg-deepsea-full/10': !$isEarthquake && n === page,
                  'bg-orange-50': $isEarthquake && n === page,
                  'border-gray-300 text-gray-500 hover:!bg-gray-50': n !== page
                }"
                @click="page = n"
              >
                {{ n }}
              </button>

              <button
                v-if="availablePages.length > 5"
                class="relative inline-flex items-center px-3 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 border-gray-300 text-gray-500 hover:!bg-gray-50 cursor"
                @click="
                  pageStart += 5;
                  page = pageStart;
                "
              >
                ...
              </button>

              <!-- next button -->
              <button
                :class="{ 'opacity-50': page === pageTotal + 1 || pageTotal === page }"
                :disabled="page === pageTotal + 1 || pageTotal === page"
                class="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium border-gray-300 text-gray-400 hover:!bg-gray-50 cursor"
                @click="
                  () => {
                    page += 1;
                    if (!availablePages.includes(page)) pageStart += 5;
                  }
                "
              >
                <!-- Heroicon name: solid/chevron-right -->
                <svg
                  class="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>

              <!-- last button -->
              <button
                class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-400 hover:!bg-gray-50"
                :class="{ 'opacity-50': page === pageTotal || pageTotal <= 5 }"
                :disabled="page === pageTotal || pageTotal <= 5"
                @click="
                  () => {
                    page = pageTotal;
                    if (pageTotal < 5) pageStart = 1;
                    else pageStart = Math.floor(pageTotal / 5) * 5;
                  }
                "
              >
                <span class="sr-only">Last</span>
                <!-- Heroicon name: solid/chevron-double-right -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="bg-gray-100 text-gray-600 rounded flex p-16 items-center justify-center text-sm"
    >
      <svg
        v-if="loading"
        class="animate-spin mr-3 h-5 w-5 text-gray-400"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      <span v-else data-test="history.noHistoryFound">
        No Policy History Found
      </span>
    </div>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";
import axios from "axios";
import moment from "moment";
import { Constants } from "@/config";
import { NumberFiltersMixin } from "@/components/policy-wizard/utils/mixins/formatting";

export default {
  mixins: [NumberFiltersMixin],

  data() {
    return {
      policies: [],
      page: 1,
      pageStart: 1,
      pageSize: 10,
      rowTotal: 0,
      loading: false,
      format,
      parseISO,
      Date,
      Constants
    };
  },
  computed: {
    pageTotal() {
      return Math.ceil(this.rowTotal / this.pageSize);
    },
    availablePages() {
      if (this.policies.length === 0) return 0;
      return this.pages.slice(this.pageStart, this.pageStart + 5);
    },
    rowBeginning() {
      if (this.policies.length === 0) return 0;
      return this.page === 1 ? this.page : (this.page - 1) * 10;
    },
    rowEnd() {
      if (this.rowTotal <= 10) return this.rowTotal;

      const { page, pageSize, rowTotal, rowBeginning, pageTotal } = this;

      const isOnLastPage = page === pageTotal;

      // if remainder is 0, and on last page, add 10 instead to get correct result while on last page
      const remainder = rowTotal % pageSize ? rowTotal % pageSize : 10;

      return isOnLastPage ? rowBeginning + remainder : page * pageSize;
    },
    pages() {
      return [...Array(Math.ceil(this.rowTotal / 10) + 1).keys()]; // slices page numbers into an iterable array, ex: [1, 2, 3, 4, 5, ...]
    },
    skip() {
      if (this.page === 1) return 0;
      else return (this.page - 1) * 10;
    },
    isInsured() {
      return this.$store.getters["auth/accountType"] === "policyholder";
    },
    filteredPolicies() {
      return this.policies;
    }
  },
  watch: {
    async page() {
      if (!this.isInsured) this.getPolicies();
    }
  },
  async mounted() {
    this.policies = [];
    await this.getPolicies();
  },
  methods: {
    filterPolicies(policies) {
      if (!policies) return [];

      const locations = policies.reduce((starter, item) => {
        const key = item.address;

        if (starter[key]) {
          const current = starter[key];
          starter[key] = [...current, item];
        } else {
          starter[key] = [item];
        }

        return starter;
      }, {});

      return Object.entries(locations).map(([key, value]) => {
        const sorted = value.sort((a, b) => {
          const momentA = moment(a.inceptionDate);
          const momentB = moment(b.inceptionDate);
          return momentA.isBefore(momentB) ? 1 : -1;
        });

        const [activePolicy] = sorted;
        return {
          name: activePolicy.insuredName,
          address: key,
          productId: activePolicy.productId,
          policyNo: activePolicy.policyNo,
          totalPremium: activePolicy.totalPremium,
          policies: sorted,
          inceptionDate: activePolicy.inceptionDate
        };
      });
    },
    async getPolicies() {
      this.loading = true;

      const {
        data: { payload }
      } = await axios.get(
        `${Constants.BASE_PSDN_URL}/api/v1/policies/GetPolicyHistorical/${this.$route.params.policyNo}?take=10&skip=${this.skip}`,
        {
          headers: {
            Authorization: this.$store.state.auth.token
          }
        }
      );
      this.rowTotal = payload.resultCount;
      this.policies = payload.policies;
      this.$store.commit("ADD_HISTORICAL_POLICIES", payload.policies);
      this.loading = false;

      // const userType = this.$route.meta?.userType || 1;
      // if (userType === 1 && (!this.$internal || !this.isInsured))
      //   this.policies = this.policies.filter((policy) => policy);
    }
  }
};
</script>
